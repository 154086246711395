<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-header-vue border spacer title="标签管理">
      </p-header-vue>
      <p-select-vue
          :limit="3"
          :model="selectForm"
          :query-btn-auth="'RP-RP-CX'"
          :reset-btn-auth="'RP-RP-CZ'"
          class="p-select"
          label-width="100px"
          @reset="reset"
          @select="query"
      >
        <el-form-item slot="1" label="标签类型" prop="type">
          <el-select v-model="selectForm.type" placeholder="请选择">
            <el-option
                v-for="item in typeData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item slot="1" label="标签编码" prop="code">
          <el-input v-model="selectForm.code" maxlength="20" placeholder="请输入" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="1" label="标签名称" prop="code">
          <el-input v-model="selectForm.name" maxlength="20" placeholder="请输入" type="text"></el-input>
        </el-form-item>

        <el-form-item slot="2" label="绑定状态" prop="status">
          <el-select v-model="selectForm.related" placeholder="请选择">
            <el-option
                v-for="item in statusData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </p-select-vue>
    </div>
    <div class="p-content" style="padding-top: 15px;">
      <div class="main-top">
        <div class="top-left">
          <span>标签列表</span>
        </div>
        <div class="top-right">
          <el-button type="default" @click="deleteBatch">批量删除</el-button>
          <el-button type="primary" @click="addLabel">新增</el-button>
        </div>
      </div>

      <el-table ref="customerInfoTable" v-loading="recordsLoading" :data="records"
                :header-cell-style="handleHeaderCellStyle"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                border default-expand-all
                row-key="id" stripe
                @selection-change="handleSelection"
      >
        <el-table-column :selectable="checkSelectable" fixed="left" type="selection" width="50"/>

        <el-table-column align="center" label="序号" type="index" width="50">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="标签编码" prop="code"></el-table-column>
        <el-table-column label="标签名称" prop="name"></el-table-column>
        <el-table-column :formatter="typeFormat" label="标签类型" prop="type"></el-table-column>
        <el-table-column :formatter="relatedFormat" label="绑定状态" prop="related"></el-table-column>
        <el-table-column label="更新人" prop="updater"></el-table-column>
        <el-table-column label="更新时间" prop="updated"></el-table-column>
        <el-table-column label="操作" prop="operate">
          <template slot-scope="scope">
            <div class="operate-button">
              <el-button size="medium" type="text" @click="showModify(scope.row)">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          :current-page.sync="paging.current"
          :page-size="paging.size"
          :page-sizes="pageSizes"
          :total="paging.total"
          background
          class="p-page"
          layout="total, sizes, prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <div class="add_style">
       <el-dialog
          :visible.sync="dialogVisible"
          title="添加标签"
          width="20%">
        <el-form :model="addData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item style="margin:0px 0 20px" label="标签类型">
            <el-select v-model="addData.type" placeholder="请选择">
              <el-option
                  v-for="item in addLabelTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin:20px 0" label="标签编码" prop="code">
            <el-input v-model="addData.code"  maxlength="20" type="text"></el-input>
          </el-form-item>
          <el-form-item style="margin:20px 0" label="标签名称" prop="name">
            <el-input v-model="addData.name" maxlength="20" type="text"></el-input>
          </el-form-item>
          <el-form-item style="padding:20px 0">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <div class="add_style">
      <el-dialog
          :visible.sync="updVisible"
          title="编辑标签"
          width="20%">
        <el-form :model="updData" :rules="rules" ref="ruleFormAmend" label-width="100px" class="demo-ruleForm">
          <el-form-item style="margin:0px 0 20px" label="标签类型">
            <el-select v-model="updData.type" placeholder="请选择">
              <el-option
                  v-for="item in addLabelTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin:20px 0" label="标签编码" prop="code">
            <el-input v-model="updData.code"  maxlength="20" type="text"></el-input>
          </el-form-item>
          <el-form-item style="margin:20px 0" label="标签名称" prop="name">
            <el-input v-model="updData.name" maxlength="20" type="text"></el-input>
          </el-form-item>
          <el-form-item style="padding:20px 0">
            <el-button @click="updVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitFormAmend('ruleFormAmend')">确 定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>

</template>

<script>

import PHeaderVue from "@/components/pdt/PHeader.vue";
import {changeMethods, dateOption, pagingMethods, tableStyle} from "@/util/mixins";
import {deleteBatch, genCode, labelAdd, labelGet, labelPage, labelUpdate} from "@/service/system/label";
import PSelectVue from "@/components/pdt/PSelect.vue";

export default {
  components: {PSelectVue, PHeaderVue},
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  data() {
    return {
      updVisible: false,
      statusData: [
        {
          value: '',
          label: '全部'
        },
        {
          value: true,
          label: '已绑定'
        },
        {
          value: false,
          label: '未绑定'
        },
      ],
      typeData: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'CUSTOMER',
          label: '客户标签'
        },
      ],
      addLabelTypes: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'CUSTOMER',
          label: '客户标签'
        },
      ],
      tableData: [],
      dialogVisible: false,
      recordsLoading: true,
      records: [],
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1,
      },
      selections: [],
      pageSizes: [15, 30, 50, 100],
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      selectForm: {
        id: '',
        name: '',
        code: '',
        type: '',
        related: ''
      },
      addData: {
        name: '',
        code: '',
        type: 'CUSTOMER',
        related: ''
      },
      updData: {
        id: '',
        name: '',
        code: '',
        type: 'CUSTOMER',
        related: ''
      },
       rules: {
          code: [
            { required: true, message: '请输入标签编码', trigger: 'blur' },
          ],
          name: [
            { required: true, message: '请输入标签名称', trigger: 'blur' },
          ],
        }
    }
  },
  created() {
    this.getDicts('sys-label-type').then(
        ({ data }) =>{
          (this.typeData = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ]);
          this.addLabelTypes = data;
        }
    );
  },
  mounted() {
    this.query();
  },
  methods: {
    async addLabel() {
      const {success, data} = await genCode()
      if (!success) {
        this.toast('标签编码获取失败，请重新获取')
        return
      }
      this.addData = {
        name: '',
        code: data,
        type: 'CUSTOMER',
        related: ''
      }
      this.dialogVisible = true
    },
    submitFormAmend(formName){
     this.$refs[formName].validate((valid) => {
          if (valid) {
            this.modify()
          } else {
            return false;
          }
        });
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.save()
          } else {
            return false;
          }
        });
      },
    checkSelectable(row) {
      return !row.related
    },
    deleteBatch() {
      if (this.selections.length === 0) {
        this.$confirm('请选择一条数据！', '提示', {
          type: "warning"
        })
        return
      }
      let ids = this.selections.map(e => e.id);
      this.$confirm(`确认要删除选中的信息`, '提示', {type: 'warning'})
          .then(async () => {
            const {success} = await deleteBatch(ids)
            if (success) {
              this.toast('操作成功', 'success')
              this.query()
            }
          })
    },
    relatedFormat(row) {
      if (row.related) {
        return '已绑定'
      }
      return '未绑定'
    },
    typeFormat(row) {
      if ("CUSTOMER" === row.type) {
        return '客户标签'
      }else if(row.type === 'SALE'){
        return '发票标签'
      }
      return '--'
    },
    async save() {
      const {success} = await labelAdd(this.addData)
      if (success) {
        this.addData = {
          name: '',
          code: '',
          type: 'CUSTOMER',
          status: ''
        }
        await this.query()
        this.dialogVisible = false
      }
    },
    async showModify(row) {
      this.updData = {
        name: '',
        code: '',
        type: 'CUSTOMER',
        related: ''
      }
      this.updVisible = true
      this.updData.id = row.id
      const {success, data} = await labelGet(this.updData.id)
      if (success) {
        this.updData.name = data.name
        this.updData.code = data.code
        this.updData.type = data.type
      }
    },
    async modify() {
      const {success} = await labelUpdate(this.updData)
      if (success) {
        this.toast('操作成功', 'success')
        this.updVisible = false
        this.query()
      }
    },
    //查询
    async query() {
      let param = {};
      if (this.selectForm.code) {
        param.code = this.selectForm.code
      }
      if (this.selectForm.name) {
        param.name = this.selectForm.name
      }
      if (this.selectForm.type) {
        param.type = this.selectForm.type
      }
      if (this.selectForm.related !== '') {
        param.related = this.selectForm.related
      }
      param.current = this.paging.current
      param.size = this.paging.size
      this.recordsLoading = true;
      this.paging.searchCount = true;
      const {success, data} = await labelPage(param);
      if (success) {
        this.records = data.records
        this.paging.total = data.total
        this.recordsLoading = false

      }

    },
    //表单重置
    reset() {
      this.selectForm = {
        id: '',
        name: '',
        code: '',
        type: '',
        related: ''
      }
    },
    handleSelection(value) {
      this.selections = value
      // if (this.selections.length === 0) {
      // } else {
      // }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.paging.size = val;
      this.query();
    },

    handleCurrentChange(val) {
      this.paging.current = val;
      this.query();
    },
  }
}
</script>

<style lang="scss" scoped>
.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }

  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}

.append-btn {
  width: 135px;
}

.operate-button {
  display: flex;
  justify-content: center;

  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.p-header {
  background-color: #fff;
}

.p-select {
  padding: 24px;

  ::v-deep .el-form-item__label {
    text-align: left;
  }
}

.p-content {
  padding-top: 0;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  .table-btn {
    padding: 0 !important;
  }
}

.view {
  position: absolute;
  top: 10px;
  left: 88px;
}
.add_style {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 20px 0 0;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
    font-size: 12px;
    left: 40px;
  }
}
</style>
